import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-light.svg';
import PublicHeader from '../../components/global/PublicHeader';
import history from '../../helpers/history';
import { Redirect } from 'react-router-dom';
import { Formik, getIn } from 'formik';
import { object, string } from 'yup';
import TextField from '../../components/UI/TextField';
import { showLoading } from '../../redux/loader/LoaderActions';
import { login } from '../../redux/user/UserActions';
import { FormattedMessage } from 'react-intl';

const Login = (props) => {
  const dispatch = useDispatch();
  /*if (localStorage.getItem('_YSLDANTKEN') && localStorage.getItem('_YSLDANTKEN') !== undefined) {
    return <Redirect from={'/'} to={`/admin/dashboard`} />;
  }*/
  return (
    <div className="layout page-auth">
      <PublicHeader />

      <div className="page-content">
        <div className="auth-card">
          <div className="auth-header">
            <h3>
              <FormattedMessage id="SIGN_IN_CONTINUE" />
            </h3>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              //Call Api here
              const payload = {
                email: values.email,
                password: values.password,
              };
              dispatch(showLoading());
              dispatch(login(payload));
              //history.push('/admin/dashboard');
            }}
            validationSchema={object().shape({
              email: string()
                .email(<FormattedMessage id="WRONG_EMAIL" />)
                .required(<FormattedMessage id="EMAIL_REQUIRED" />),
              password: string()
                .min(8, <FormattedMessage id="MIN_PASS" />)
                .required(<FormattedMessage id="PASSWORD_REQUIRED" />),
            })}
          >
            {(renderProps) => {
              const { values, errors, touched, handleChange, handleBlur, handleSubmit } = renderProps;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <FormattedMessage id="ENTER_EMAIL">
                      {(msg) => (
                        <TextField
                          id="email"
                          type="email"
                          className="form-control "
                          placeholder={msg}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'email') && getIn(errors, 'email')}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="form-group mb-4">
                    <FormattedMessage id="ENTER_PASSWORD">
                      {(msg) => (
                        <TextField
                          id="password"
                          type="password"
                          className="form-control w-100 "
                          placeholder={msg}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'password') && getIn(errors, 'password')}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className="form-group">
                    <div className="text-end">
                      <Link className="text-dark" to="/forgot-password">
                        <FormattedMessage id="FORGOT_PASSWORD" />
                      </Link>
                    </div>
                  </div>
                  <button className="btn btn-dark w-100" type="submit">
                    <FormattedMessage id="SIGN_IN" />
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
