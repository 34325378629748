import React from 'react';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';

const Home = (props) => {
  return (
    <div className="page-wrapper header-dark">
      <PublicHeader />
      <h1>Home</h1>
      <PublicFooter />
    </div>
  );
};

export default Home;
