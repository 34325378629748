import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
const PublicFooter = () => {
  return (
    <footer>
      <div className="d-flex align-items-center">
        <p className="mb-0 me-3 font-12">
          <FormattedMessage id="POWERED_BY" />
        </p>
        <img src={logo} className="img-fluid" alt="" />
      </div>
    </footer>
  );
};
export default withRouter(PublicFooter);
