export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = (message, success) => {
  return {
    type: SHOW_MODAL,
    payload: { message, isshow: true, success: success },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
    payload: { message: '', isshow: false, success: false },
  };
};
