const config = {
  api: {
    URL: process?.env.REACT_APP_API_URL,
  },
  CLIENT_ID: process?.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process?.env.REACT_APP_CLIENT_SECRET,
  CLIENT_DEVICE: process?.env.REACT_APP_DEVICE,
  GOOGLE_PLACE_API_KEY: process?.env.REACT_APP_GOOGLE_PLACE_API_KEY,
};

export default config;
