/* REST */
import { GET, POST } from '../../services/rest.service';
// import store from '../store';
import { hideLoading } from '../loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import history from '../../helpers/history';
import { authUser, getCurrentUser } from '../../services/apiEndpoint';

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload) => {
  return (dispatch) => {
    authUser(payload)
      .then(({ data }) => {
        localStorage.setItem('_YSLDANTKEN', true);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            isLoggedIn: true,
            user: data.user,
            message: 'logged in successfully',
          },
        });
        history.push('/admin/dashboard');
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            isLoggedIn: false,
            user: {},
            type: undefined,
            message: err.message,
          },
        });
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };
};

export const getLoggedInUser = () => {
  return (dispatch) => {
    getCurrentUser()
      .then(({ data }) => {
        //console.log(data, 'Data');
        dispatch({
          type: USER,
          payload: {
            user: data.user,
            type: data.type,
          },
        });
      })
      .catch((err) => {
        console.log('failure');
        localStorage.removeItem('_YSLDANTKEN');
        window.location.reload();
        dispatch({
          type: LOGIN_FAILURE,
          payload: {
            user: {},
            type: undefined,
            isLoggedIn: false,
            message: err.message,
          },
        });
      });
  };
};

export const logoutUser = (email) => {
  return (dispatch) => {
    POST('/admin/auth/logout', {
      deviceId: '',
    })
      .then(({ data }) => {
        localStorage.removeItem('_YSLDANTKEN');
        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
            type: undefined,
            user: {},
            message: '',
          },
        });
        history.replace('/');
      })
      .catch((err) => {
        localStorage.removeItem('_YSLDANTKEN');
        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
            type: undefined,
            user: {},
            message: '',
          },
        });
        history.replace('/');
      });
  };
};
