import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AsyncComponent from './app/components/hoc/AsyncComponent';
// Genreal Meethods
import AppLoader from './app/components/loaders/AppLoader';
import ErrorSucessModal from './app/components/Modals/ErrorSucessModal';
import ForgotPassword from './app/containers/auth/ForgotPassword';
import Login from './app/containers/auth/Login';
import ResetPassword from './app/containers/auth/ResetPassword';
import { hideModal } from './app/redux/error/ErrorAction';
import { getLoggedInUser } from './app/redux/user/UserActions';
import Home from './app/containers/public/Home';
import OTPVerification from './app/containers/auth/OTPVerification';
// import Sidebar from './app/components/global/Sidebar';

// Authenticate Route
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (localStorage.getItem('_YSLDANTKEN') ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
  );
};

export const CheckAuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (localStorage.getItem('_YSLDANTKEN') ? <Redirect to={{ pathname: '/admin/dashboard', state: { from: props.location } }} /> : <Component {...props} />)}
    />
  );
};

const AdminLayoutAsync = AsyncComponent(() => import('./app/containers/layout/AdminLayout'));

const App = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loading);
  const modal = useSelector((state) => state.modal);

  useEffect(() => {
    if (localStorage.getItem('_YSLDANTKEN')) {
      dispatch(getLoggedInUser());
    }
  }, []);

  const handleToggle = () => {
    dispatch(hideModal());
  };

  const { match } = props;

  return (
    <>
      {modal.isshow && <ErrorSucessModal onToggle={handleToggle} {...modal} />}
      {loader.isLoading && <AppLoader title={loader.title} />}
      <div className="layout">
        <div className="overlay"></div>

        <Switch>
          {/* Public routes */}
          {/*<Route exact path="/" component={Home} />*/}
          <CheckAuthRoute exact path="/" component={Login} />
          <CheckAuthRoute exact path={`/forgot-password`} component={ForgotPassword} />
          <CheckAuthRoute exact path={`/reset-password`} component={ResetPassword} />
          <CheckAuthRoute exact path={`/otp-verification`} component={OTPVerification} />
          <PrivateRoute path={'/admin'} component={AdminLayoutAsync} />
          <Redirect from={match.url} to={`/`} />
        </Switch>
      </div>
    </>
  );
};

export default withRouter(App);
