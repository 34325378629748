import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import logo from '../../../assets/images/logo-light.svg';
import PublicHeader from '../../components/global/PublicHeader';
import TextField from '../../components/UI/TextField';
import history from '../../helpers/history';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { forgotPassword } from '../../services/apiEndpoint';

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const forgotPass = (payload) => {
    dispatch(showLoading());
    forgotPassword(payload)
      .then((res) => {
        localStorage.setItem('userEmail', payload.email.toLowerCase());
        localStorage.setItem('screen', 'forgotpassword');
        const user = localStorage.getItem('userEmail');
        //console.log(user, 'User EMail');
        dispatch(hideLoading());
        if (user) {
          history.push('/otp-verification');
        } else {
          history.push('/login');
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };
  return (
    <div className="layout page-auth">
      <PublicHeader />

      <div className="page-content">
        <div className="auth-card">
          <div className="auth-header">
            <h3>
              <FormattedMessage id="FORGOT_PASSWORD" />
            </h3>
            <p>
              <FormattedMessage id="EMAIL_SEND_1" /> <br /> <FormattedMessage id="EMAIL_SEND_2" />
            </p>
          </div>
          <Formik
            onSubmit={(values) => {
              forgotPass(values);
            }}
            initialValues={{
              email: '',
            }}
            validationSchema={object().shape({
              email: string()
                .email(<FormattedMessage id="WRONG_EMAIL" />)
                .required(<FormattedMessage id="EMAIL_REQUIRED" />),
            })}
          >
            {({ handleSubmit, handleChange, errors, values, handleBlur, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="EMAIL" />
                  </label>
                  <TextField
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    value={values.email}
                    name="email"
                    error={touched.email && errors.email}
                  />
                </div>

                <button className="btn btn-dark w-100">
                  <FormattedMessage id="SUBMIT" />
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
