import { isArray, isBoolean, isNumber, isObject, isString, round } from 'lodash';

import { serialize } from 'object-to-formdata';

import moment from 'moment';
/*const convertJsonToFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (isString(value) || isNumber(value)) {
      formData.append(key, value);
    } else if (isArray(value)) {
      formData.append(key, JSON.stringify(value));
    } else if (isObject(value)) {
      formData.append(key, value, value.name);
    }
  });

  return formData;
};
*/

export const UserLabels = {
  ALL: 'All',
  PENDING_DATA: 'Pending data',
  PENDING_VERIFICATION: 'Pending verification',
  BLOCKED: 'Blocked',
  VERIFICATION_COMPLETED: 'Verification completed',
  VERIFICATION_FAILED: 'Verification failed',
  READY_FOR_VERIFICATION: 'Ready for verification',
};

export const UserStatuses = {
  ALL: 'ALL',
  PENDING_DATA: 'PENDING_DATA',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  BLOCKED: 'BLOCKED',
  VERIFICATION_COMPLETED: 'VERIFICATION_COMPLETED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  READY_FOR_VERIFICATION: 'READY_FOR_VERIFICATION',
};

export const JobLabels = {
  ALL: 'All',
  DRAFT: 'Draft',
  PENDING_VERIFICATION: 'Pending verification',
  AVAILABLE_FOR_BIDS: 'Available for bids',
  STARTED: 'Started',
  WAITING_FOR_CONTRACTOR: 'Waiting for contractor',
  CONTRACTOR_WORKING: 'Contractor working',
  NO_BIDS_PLACED: 'No bids placed',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
};

export const JobStatus = {
  ALL: 'ALL',
  DRAFT: 'DRAFT', // When created by visitor but luck some fields to be pending for verification
  PENDING_VERIFICATION: 'PENDING_VERIFICATION', // When job is created and waiting auction start from admin
  AVAILABLE_FOR_BIDS: 'AVAILABLE_FOR_BIDS', // When admin started auction
  STARTED: 'STARTED', // When first bid received and timer started
  WAITING_FOR_CONTRACTOR: 'WAITING_FOR_CONTRACTOR', // When bidding time ends and we found at least one winner
  NO_BIDS_PLACED: 'NO_BIDS_PLACED', // When timer runs out and no bids were placed
  CONTRACTOR_WORKING: 'CONTRACTOR_WORKING', // When user hired company to make job done
  COMPLETED: 'COMPLETED', // When client approve that work was done and we count it to company's "jobs done"
  CANCELED: 'CANCELED', // When user cancels the job
};

export const JobHints = {
  DRAFT: 'When created by visitor but luck some fields to be pending for verification',
  PENDING_VERIFICATION: 'When project is created and waiting auction start from admin',
  AVAILABLE_FOR_BIDS: 'When admin started auction',
  STARTED: 'When first bid received and timer started',
  WAITING_FOR_CONTRACTOR: 'When bidding time ends and we found at least one winner',
  NO_BIDS_PLACED: 'When timer runs out and no bids were placed',
  CONTRACTOR_WORKING: 'When user hired company to make project done',
  COMPLETED: 'When client approve that work was done and we count it to company\'s "projects done"',
  CANCELED: 'When user cancels the project',
};

export const mapWorkHour = {
  SMALL: '< 3 Hours',
  AVERAGE: '3 - 6 Hours',
  LARGE: '> 1 Day',
};

export const NOT_EDITABLE_STATUSES = [UserStatuses.BLOCKED];

const convertJsonToFormData = (data) => {
  const formData = serialize(data, { indices: true });

  return formData;
};
export default convertJsonToFormData;

export const _dateFormatter = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date).format(format);
};

export const convertMobileNumberFormatter = (number, countryCode) => {
  return number
    .replace(countryCode, '')
    .replace(/ /g, '')
    .replace(/[^\w-]+/g, '')
    .replace(/[^\w)]+/g, '')
    .replace(/[^\w()]+/g, '');
};

export const CSSSTRING = (x, sortingSequence, sortingValue) => {
  if (sortingSequence === '') {
    return '';
  } else {
    if (sortingSequence === 'asc') {
      if (sortingValue === x.value) {
        return 'asc';
      } else {
        return '';
      }
    }
    if (sortingSequence === 'desc') {
      if (sortingValue === x.value) {
        return 'desc';
      } else {
        return '';
      }
    }
  }
};

export const numberWithCommas = (x, fixedDecimal = false) => {
  let number = round(x, 2);

  if (fixedDecimal) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('show-filter');
};

export const PageLimitSize = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '80',
    value: 80,
  },
  {
    label: '100',
    value: 100,
  },
];

export const NotificationTypeRedirection = (type, jobId) => {
  if (type === 'ADMIN_NEW_COMPANY_SIGNUP') {
    return '/admin/pendingcompany/list';
  }
  if (type === 'ADMIN_NEW_JOB_CREATED') {
    return `/admin/auction/detail/${jobId}`;
  }
  if (type === 'ADMIN_AUCTION_STARTED') {
    return `/admin/auction/detail/${jobId}`;
  }
  if (type === 'ADMIN_AUCTION_COMPLETED') {
    return `/admin/auction/detail/${jobId}`;
  }
};
