import React from 'react';
import { useDispatch } from 'react-redux';
import history from '../../helpers/history';
import { showModal } from '../../redux/error/ErrorAction';
import OtpInput from 'react-otp-input';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import PublicHeader from '../../components/global/PublicHeader';
import { Formik } from 'formik';
import { IconOTPVerification } from '../../components/icons/Icons';
import { forgotPassVerifyEmail } from '../../services/apiEndpoint';
import { FormattedMessage } from 'react-intl';

const OTPVerification = () => {
  const dispatch = useDispatch();

  const forgotVerifyEmail = (payload) => {
    dispatch(showLoading());

    forgotPassVerifyEmail(payload)
      .then(({ data }) => {
        if (data.isValid) {
          localStorage.setItem('otp', payload.otp);
          dispatch(hideLoading());

          history.push('/reset-password');
        } else {
          dispatch(hideLoading());
          dispatch(showModal(<FormattedMessage id="CORRECT_OTP" />));
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  return (
    <div className="page-wrapper page-wrapper-auth">
      <PublicHeader />
      <div className="page-content page-vh">
        <div className="form-content">
          <div className="text-center mb-4">
            <IconOTPVerification />
          </div>
          <h3>
            <FormattedMessage id="OTP_VERIFICATION" />
          </h3>
          <p>
            <FormattedMessage id="ADD_VERIFICATION_CODE" />
          </p>
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => {
              forgotVerifyEmail(values);
            }}
            initialValues={{
              email: localStorage.getItem('userEmail'),
              otp: '',
            }}
          >
            {({ handleSubmit, setFieldValue, handleChange, values, errors, touched, handleBlur }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="VERIFICATION_CODE" />
                  </label>
                  <OtpInput
                    className="otp-control"
                    name="otp"
                    containerStyle={{ color: 'black' }}
                    inputStyle={{ color: 'black' }}
                    focusStyle={{ color: 'pink' }}
                    value={values.otp}
                    separator={<span>-</span>}
                    onChange={(val) => {
                      setFieldValue('otp', val);
                    }}
                    numInputs={6}
                  />
                </div>
                <div className="form-group mb-0">
                  <button className="btn btn-primary w-100" type="submit">
                    <FormattedMessage id="VERIFY" />
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default OTPVerification;
