import axios from 'axios';
import config from '../../config';
import history from '../helpers/history';
import convertJsonToFormData from '../helpers/helper';

axios.defaults.baseURL = config.api.URL;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (method, url, options = { params: {}, payload: {} }) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
    withCredentials: true,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const requestV2 = (method, url, options = { params: {}, payload: {} }) => {
  const request = {
    baseURL: config.api.URL.replace('v1', 'v2'),
    url,
    method,
    params: options.params,
    data: options.payload,
    withCredentials: true,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const POSTV2 = (path, payload) => {
  return requestV2('POST', path, { payload: convertJsonToFormData(payload) });
};

export const POST = (path, payload) => {
  return request('POST', path, { payload: convertJsonToFormData(payload) });
};

export const GET = (path, params) => {
  return request('GET', path, { params });
};

export const GETALL = (path, filters) => {
  return request('GET', path, { params: filters });
};

export const PUT = (path, payload) => {
  return request('PUT', path, { payload: convertJsonToFormData(payload) });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, { payload: convertJsonToFormData(payload) });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

// axios.interceptors.request.use((request) => {
//   // Setting up headers
//   const token = localStorage.getItem('_YSLDANTKEN');
//   //console.log(token, 'Token');
//   let headers = {
//     'x-client-id': config.CLIENT_ID,
//     'x-client-secret': config.CLIENT_SECRET,
//     'x-client-device': config.CLIENT_DEVICE,
//   };
//   if (token) {
//     headers['x-yourlead-auth-token'] = `${token}`;
//   }

//   request.headers = headers;
//   return request;
// });

/**
 * RESPONSE INTERCEPTOR
 */
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response.data;
  },
  (error) => {
    if (error.code === 401) {
      localStorage.removeItem('_YSLDANTKEN');
      history.replace('/');
    }
    return Promise.reject(error.response.data);
  },
);
