import React from 'react';
import PublicHeader from '../../components/global/PublicHeader';
import history from '../../helpers/history';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { string } from 'yup';
import { object } from 'yup';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { showModal } from '../../redux/error/ErrorAction';
import { resetPassword } from '../../services/apiEndpoint';
import TextField from '../../components/UI/TextField';
import { FormattedMessage } from 'react-intl';

const ResetPassword = (props) => {
  const dispatch = useDispatch();

  const resetPass = (values) => {
    dispatch(showLoading());
    const payload = {
      email: values.email,
      otp: values.otp,
      newPassword: values.pwd,
      confirmPassword: values.conpwd,
    };

    resetPassword(payload)
      .then((res) => {
        localStorage.removeItem('userEmail');
        localStorage.removeItem('otp');
        localStorage.removeItem('screen');
        dispatch(hideLoading());

        history.push('/login');
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  return (
    <div className="layout page-auth">
      <PublicHeader />

      <div className="page-content">
        <div className="auth-card">
          <div className="auth-header">
            <h3>
              <FormattedMessage id="RESET_PASSWORD" />
            </h3>
            <p>
              <FormattedMessage id="MIN_PASS" />
            </p>
          </div>

          <Formik
            initialValues={{
              email: localStorage.getItem('userEmail'),
              otp: localStorage.getItem('otp'),
              pwd: '',
              conpwd: '',
            }}
            onSubmit={(values) => {
              resetPass(values);
            }}
            validationSchema={object().shape({
              pwd: string()
                .min(8, <FormattedMessage id="NEW_PASS_MIN" />)
                .required(<FormattedMessage id="NEW_PASS_REQUIRED" />),
              conpwd: string()
                .min(8, <FormattedMessage id="CONFIRM_PASS_MIN" />)
                .required(<FormattedMessage id="CONFIRM_PASS_REQUIRED" />),
            })}
          >
            {({ handleSubmit, setFieldValue, handleChange, errors, values, handleBlur, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="NEW_PASSWORD" />
                  </label>
                  <TextField onChange={handleChange} type="password" className="form-control" value={values.pwd} name="pwd" error={touched.pwd && errors.pwd} />
                </div>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="CONFIRM_PASSWORD" />
                  </label>
                  <TextField value={values.conpwd} onChange={handleChange} type="password" className="form-control" name="conpwd" error={touched.conpwd && errors.conpwd} />
                </div>
                <button className="btn btn-dark w-100" type="submit">
                  <FormattedMessage id="RESET_PASSWORD" />
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
