import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { get, isEmpty } from 'lodash';
import Imgavatar from '../../../assets/images/avatar/2.png';
import { MenuIcon, NotificationIcon } from '../../components/icons/Icons';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import history from '../../helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/user/UserActions';
import { User } from 'lucide-react';
const PublicHeader = (props) => {
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const toggle = () => setDropDownMenu((prevState) => !prevState);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  /*const handleLogout = () => {
    history.replace('/');
    localStorage.removeItem('_YSLDANTKEN');
  };*/
  return (
    <>
      <header className="header">
        <div className="header-container">
          <nav className="nav justify-content-between aling-items-center">
            <div className="d-flex">
              <button
                className="btn d-md-none"
                onClick={() => {
                  document.body.classList.toggle('sidebar-mini');
                }}
              >
                <MenuIcon />
              </button>
              <div className="navbar-logo">
                <NavLink to="/admin/dashboard" className="navbar-brand c-pointer">
                  <img src={logo} alt="" className="img-fluid" />
                </NavLink>
              </div>
            </div>

            <ul className="navbar-nav nav ms-auto">
              {!isEmpty(user) && (
                <li className="nav-item">
                  <NavLink className="nav-link " to="/admin/notification">
                    <NotificationIcon />
                  </NavLink>
                </li>
              )}
            </ul>

            {!isEmpty(user) && (
              <Dropdown className="ms-2" isOpen={dropDownMenu} toggle={toggle}>
                <DropdownToggle caret className="nav-link dropdown-no-icon dropdown-toggle p-0 bg-transparent border-0 shadow-none mx-auto">
                  {user.avatar ? <img src={user.avatar ? get(user, 'avatar.mediaUrl') : null} alt="" className="nav-avatar ml-2" /> : <User />}
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem onClick={() => history.push('/admin/profile')}>Profile</DropdownItem> */}
                  <DropdownItem onClick={() => history.push('/admin/profile/change-password')}>Change Password</DropdownItem>
                  {/*<DropdownItem onClick={() => history.push('/admin/')}>Contact Us</DropdownItem>
                  <DropdownItem onClick={() => history.push('/admin/')}>Privacy Policy</DropdownItem>
                  <DropdownItem onClick={() => history.push('/admin/')}>Terms & Conditions</DropdownItem>
            <DropdownItem onClick={() => history.push('/admin/')}>About Us</DropdownItem>*/}

                  <DropdownItem onClick={() => dispatch(logoutUser(user.email))}>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};
export default withRouter(PublicHeader);
